import React from "react";
import styles from "./style/home.module.css";

import EmailSignUp from "../components/EmailSignUp";
import ShopifyCollection from "../components/ShopifyCollection";
import InstagramFeed from "../components/InstagramFeed";

const Home = (props) => {

const showcaseCollection = 162205925431;

  return(
    <>
    <div>
      <div className={styles.heroImg}>
        <div className={`onlyMobile ${styles.shopButton}`}>
          <a href="/browse" className="btn btn-dark">Shop now</a>
        </div>
      </div>
      <p className={styles.intro}>
        Take the mountain with you
      </p>
    </div><div className={`${styles.emailSection}`}>
      <div className="container">
        <div className="row">
          <EmailSignUp/>
        </div>
      </div>
    </div>
      <div className="container mb-3">
        <div className="row">
          <div className="col-sm-12">
            <h3 className="mt-4 mb-4">New Arrivals</h3>
            <ShopifyCollection collectionId={showcaseCollection} componentId="ShowcaseItems"/>
          </div>
        </div>

      </div>
      <div className="container mb-4">
        <div className="row">
          <div className="col-sm-12">
            <InstagramFeed account="_mountyama" numberOfMediaElements="3" classes={{wrapper: "container"}} />
          </div>
        </div>
      </div>
      </>
  )
}

export default Home;
