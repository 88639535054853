import React from 'react';

import styles from "./style/mainLayout.module.css";
import Menu from './Menu';
import Footer from './Footer';

const MainLayout = (props) => {

  return(
    <>
    <div className={styles.page}>
        <section className={styles.header}>
          <Menu activeLink={"home"}/>
        </section>
        <section className={styles.content}>
          {props.children}
        </section>
        <section className={styles.footer}>
          <Footer/>
        </section>
    </div>
    </>
  )
}

export default MainLayout;
