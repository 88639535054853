import React, { useState, useEffect } from 'react';
import { options } from '../services/shopify';
import Loading from "../components/Loading";
import styles from './style/shopifyCollection.module.css'

const ShopifyCollection = (props) => {

  const {componentId, collectionId, cssClass, onLoaded} = {...props}
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (window.ShopifyBuy) {
        if (window.ShopifyBuy.UI) {
          var client = window.ShopifyBuy.buildClient({
            domain: 'mountyama.myshopify.com',
            storefrontAccessToken: '194d53fd0e5bbba6c17c45d3046e44dd',
          });
          setLoaded(false);
          window.ShopifyBuy.UI.onReady(client).then(function (ui) {
            clearInterval(intervalId);
            document.getElementById(componentId).innerHTML = "";
            ui.createComponent('collection', {
              id: collectionId,
              node: document.getElementById(componentId),
              moneyFormat: '%C2%A3%7B%7Bamount%7D%7D',
              options: options,
            });
            // look for loaded divs here before setting loaded

            //setLoaded(true);
          });
        }
      }
    }, 10);
  }, [collectionId, componentId]);


  const loadedInterval = setInterval(()=> {
    const frame = document.getElementById(componentId).querySelector('iframe');
    if(!frame)
    {
      return;
    }

    const product = frame.contentDocument.querySelectorAll('.shopify-buy__product');

    if(product.length)
    {
      clearInterval(loadedInterval);
      setLoaded(true);
      onLoaded(frame);
    }
  }, 10);

  function renderLoading() {
    return (

      // <div className={`d-flex justify-content-center ${styles.spinner}`}>
      //   <div className="spinner-border" role="status">
      //     <span className="sr-only">Loading...</span>
      //
      //   </div>
      // </div>
      <div className={`d-flex justify-content-center ${styles.spinner}`}>
        <Loading />
      </div>
    )
  }

  return(
    <>
    { !loaded && renderLoading()}
    <div id={componentId} className={cssClass}></div>
    </>
  )
}

export default ShopifyCollection;
