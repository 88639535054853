import * as React from 'react';
import withInstagramFeed from 'origen-react-instagram-feed';
import compose from 'recompose/compose';

import styles from './style/instagramFeed.module.css';

export type Props = {
  media?: Array<{
    displayImage: string,
    id?: string,
    postLink?: string,
    accessibilityCaption?: string,
  }>,
  account: string,
  classes: { [$Keys<$Call<typeof styles>>]: string },
  status: 'completed' | 'loading' | 'failed',
};

const InstagramFeed = ({ classes, media, account, status}: Props) => {
  return (
    <>
    <h3 className={styles.heading}>Find us on instagram <b><a href={`https://www.instagram.com/${account}`}>@{account}</a></b></h3>
    <div className={classes.wrapper}>
      <div className="row">
        {media &&
          status === 'completed' &&
          media.map(({ displayImage, id, postLink, accessibilityCaption }) => (
            <div className={`col-xs-12 col-sm-4 col-md-4 ${styles.col}`} key={id || displayImage}>
              <a
                href={postLink || `https://www.instagram.com/${account}/`}
              >
                <img
                  src={displayImage}
                  alt={accessibilityCaption || 'Instagram picture'}
                  className={styles.image}
                />
              </a>
            </div>
          ))}
        {status === 'loading' && <p>loading...</p>}
        {status === 'failed' && <p></p>}
      </div>
    </div>
    </>
  );
};

InstagramFeed.defaultProps = {
  media: undefined,
};

export default compose(
  withInstagramFeed,
)(InstagramFeed);
