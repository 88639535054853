import React from 'react';
import styles from './style/footer.module.css';

const Footer = (props) => {

  const year = (new Date()).getFullYear();

  return(
    <section className={styles.footer}>
      <div className="container">
        <div className="row">
          <div className="col-md-4 offset-md-2 text-center">
            <ul className="list-group list-group-flush">
              <li className={`list-group-item ${styles.footerItem}`}><a href="/privacy">Privacy Policy</a></li>
              <li className={`list-group-item ${styles.footerItem}`}><a href="/terms">Terms & Conditions</a></li>
            </ul>
          </div>
          <div className="col-md-4 text-center">
            <ul className="list-group list-group-flush">
              <li className={`list-group-item ${styles.footerItem}`}><a href="/returns">Returns Policy</a></li>
              <li className={`list-group-item ${styles.footerItem}`}>
                <p className={styles.footerItem}>Copyright © {year} Mount Yama Ltd</p>
              </li>
            </ul>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className={`trustpilot-widget ${styles.trustPilot}`} data-locale="en-GB" data-template-id="5613c9cde69ddc09340c6beb" data-businessunit-id="5f2f1e92b5cff500017c9ab1" data-style-height="50px" data-style-width="100%" data-theme="dark">
              <a href="https://uk.trustpilot.com/review/mountyama.com" target="_blank" rel="noopener">Trustpilot</a>
            </div>
          </div>
        </div>
        <div className="row">
          <div className={`col-md-12 text-center ${styles.contactDetails}`}>
            Mount Yama Ltd, 71-75 Shelton Street, London, Greater London, WC2H 9JQ, UNITED KINGDOM
          </div>
        </div>
        <div className="row">
          <div className={`col-md-12 text-center ${styles.contactDetails}`}>
            <a href="mailto:support@mountyama.com">support@mountyama.com</a>
          </div>
        </div>
      </div>
    </section>

  )
}

export default Footer;
