import { ga } from '../services/firebase';

export const scriptUrl = 'https://sdks.shopifycdn.com/buy-button/latest/buy-button-storefront.min.js';

const addToBasket = (product) => {
  const selected = product.selectedVariant;
  const ecommDetails = {
   item_id: selected.id,
   item_name: product.model.title,
   item_category: product.model.productType,
   item_variant: selected.title,
   price: selected.priceV2.amount
};
   var params = {
      currency: selected.priceV2.currencyCode,
      value: selected.priceV2.amount,
      item: product.model.title,
      items: [ecommDetails]
   }
   ga.logEvent(ga.app.firebase_.analytics.EventName.ADD_TO_CART, params);
   window.fbq('track', 'AddToCart', {currency: selected.priceV2.currencyCode, value: selected.priceV2.amount, content_name:product.model.title});
   window.pintrk('track', 'addtocart', {
    order_quantity: 1,
    currency: selected.priceV2.currencyCode,
    line_items: [
    {
      product_name: product.model.title,
      product_price: selected.priceV2.amount
    }
    ]
  });
}


const viewProduct = (product) => {
  //console.log(product);
  const selected = product.selectedVariant;
     const ecommDetails = {
      item_id: selected.id,
      item_name: product.model.title,
      item_category: product.model.productType,
      item_variant: selected.title,
      price: selected.priceV2.amount
   };
    var params = {
       currency: selected.priceV2.currencyCode,
       value: selected.priceV2.amount,
       item: product.model.title,
       items: [ecommDetails]
    }
   ga.logEvent(ga.app.firebase_.analytics.EventName.VIEW_ITEM, params);
   window.fbq('track', 'ViewContent', {currency: selected.priceV2.currencyCode, value: selected.priceV2.amount, content_name:product.model.title});
   window.pintrk('track', 'pagevisit', {
    currency: 'GBP',
    line_items: [
      {
        product_name: product.model.title,
        product_price: selected.priceV2.amount
      }
    ]
  });
}



export const options = {
"product":{
   "imageSize":"1024x1024",
   "width": '1024px',
   "templates": {
      "img": '{{#data.currentImage.src}}<div class="{{data.classes.product.imgWrapper}}" data-element="product.imgWrapper" data-product="{{data.title}}" data-images="[{{#data.images}}&#34;{{src}}&#34;,{{/data.images}}]"><img data-element="product.img" class="{{data.classes.product.img}}" src="{{data.currentImage.src}}" /></div>{{/data.currentImage.src}}',
      "options": '<div class="{{data.classes.product.colourContainer}}">{{#data.options.0.values}}<div class="{{data.classes.product.colour}}" data-colour="{{value}}" style="background-image:url(&#34;https://mountyama.com/swatches/{{value}}.jpg&#34;)">&nbsp;</div>{{/data.options.0.values}}</div>'
    },
    "contents":{
       "options":true,
    },
    "order": [
      'img',
      'options',
      'title',
      'price',
      'button',
    ],
    "classes": {
      "colourContainer": 'product-colours',
      "colour": 'product-colour',
    },
   "styles":{
      "product":{
         "@media (min-width: 601px)":{
            "max-width":"calc(33.33333% - 30px)",
            "margin-left":"30px",
            "margin-bottom":"50px",
            "width":"calc(33.33333% - 30px)"
         },
         "width": "85%",
         "img":{
            "position":"absolute",
            "left":"0",
            "right":"0",
            "top":"0",
            "cursor":"pointer"
         },
         "imgWrapper":{
            "padding-top":"calc(75% + 15px)",
            "position":"relative",
         }
      },
      "title":{
         "font-family":"Lato, sans-serif",
         "color":"#000000"
      },
      "button":{
         "font-family":"Lato, sans-serif",
         "font-weight":"bold",
         ":hover":{
            "background-color":"#46b893"
         },
         "background-color":"#4ecca3",
         ":focus":{
            "background-color":"#46b893"
         },
         "padding-left":"50px",
         "padding-right":"50px"
      },
      "price":{
         "font-family":"Lato, sans-serif",
         "font-size":"15px",
         "color":"#000000"
      },
      "compareAt":{
         "font-family":"Lato, sans-serif",
         "font-size":"12.75px",
         "color":"#000000"
      },
      "unitPrice":{
         "font-family":"Lato, sans-serif",
         "font-size":"12.75px",
         "color":"#000000"
      },
      "colourContainer": {
        "position": "relative",
        "height": "30px",
        "margin-top": "9rem",
        "margin-bottom": "0.8rem"
      },
      "colour": {
        "display": "inline-block",
        "width": "30px",
        "height": "30px",
        "background-repeat": "no-repeat",
        "border-radius": "3px",
        "margin": "0 0.5rem",
        "cursor": "pointer"
      }
   },
   "buttonDestination":"modal",
   "text":{
      "button":"Quick view"
   },
   "googleFonts":[
      "Lato"
   ],
   "events": {
      "addVariantToCart": addToBasket,
      "openModal": viewProduct
 }
},
"productSet":{
   "imageSize":"1024x1024",
   "width": '1024px',
   "templates": {
      "img": '{{#data.currentImage.src}}<div class="{{data.classes.product.imgWrapper}}" data-element="product.imgWrapper" data-product="{{data.title}}"><img data-element="product.img" class="{{data.classes.product.img}}" src="{{data.currentImage.src}}" /></div>{{/data.currentImage.src}}'
    },
   "styles":{
      "products":{
         "@media (min-width: 601px)":{
            "margin-left":"-30px"
         }
      }
   },
   "events": {
      "addVariantToCart": addToBasket,
      "openModal": viewProduct
 },
  "DOMEvents": {
      'click .shopify-buy__product-img-wrapper': function (evt, target) {
        const handle = target.dataset["product"].toLowerCase().replace(/ /g, "-");
        window.location.assign(`/product/${handle}`)
      },
      'click .product-colour': function (evt, target) {
        const index = [...target.parentElement.children].indexOf(target);
        const wrapper = target.parentElement.parentElement.querySelector(".shopify-buy__product-img-wrapper");
        let imgString = wrapper.dataset["images"];
        imgString = imgString.replace(",]", "]")
        const imgs = JSON.parse(imgString);

        wrapper.querySelector("img").setAttribute("src", imgs[index]);
      }
    }
},
"modalProduct":{
    "imageSize":"1024x1024",
    "width": '1024px',
    "templates": {
       "img": '{{#data.currentImage.src}}<div class="{{data.classes.product.imgWrapper}}" data-element="product.imgWrapper"><img data-element="product.img" class="{{data.classes.product.img}}" src="{{data.currentImage.src}}" /></div>{{/data.currentImage.src}}',
       "sizes": '<div class="{{data.classes.product.sizes}}"><a class="{{data.classes.product.sizes-link}}" target="_blank" href="/images/creator-size-guide.png">Size guide</a></div>',
       "paymentMethods": '<div><img class="{{data.classes.product.payment-method}}" src="/images/shop-pay-logo.png"/><img class="{{data.classes.product.payment-method}}" src="/images/google-pay-logo.svg"/><img class="{{data.classes.product.payment-method}}" src="/images/apple-pay-logo.svg"/></div>'
     },
    "classes": {
      "sizes": "product-sizes",
      "sizes-link": "product-sizes-link",
      "payment-method": "paymentMethodLogo"
    },
  "order": [
    'imgWithCarousel',
    'title',
    'price',
    'options',
    'description',
    'sizes',
    'buttonWithQuantity',
    'paymentMethods'
  ],
   "contents":{
      "img":false,
      "imgWithCarousel":true,
      "button":false,
      "sizes": true,
      "buttonWithQuantity":true,
      "paymentMethods":true
   },
   "styles":{
      "product":{
         "@media (min-width: 601px)":{
            "max-width":"100%",
            "margin-left":"0px",
            "margin-bottom":"0px"
         }
      },
      "button":{
         "font-family":"Lato, sans-serif",
         "font-weight":"bold",
         ":hover":{
            "background-color":"#46b893"
         },
         "background-color":"#4ecca3",
         ":focus":{
            "background-color":"#46b893"
         },
         "padding-left":"50px",
         "padding-right":"50px"
      },
      "sizes": {
        "@media (min-width: 500px)":{
          "margin-left": "calc(40% + 25px)",
        },
        "@media (min-width: 680px)":{
          "margin-left": "calc(60% + 25px)",
        },
        "margin-top": "30px",
      },
      "sizes-link": {
        "color": "#4a4a4a",
        ":visited": {
          "color": "#4a4a4a"
        },
        ":hover": {
          "color": "rgba(78,204,163,1)"
        }
      },
      "payment-method": {
         "display": "inline-block",
         "width": "80px !important",
         "padding-left": "25px",
         "padding-top": "0.5rem",
         "vertical-align": "middle"
      },
      "title":{
         "font-family":"Lato, sans-serif",
         "color":"#000000"
      },
      "price":{
         "font-family":"Lato, sans-serif",
         "color":"#000000"
      },
      "compareAt":{
         "font-family":"Lato, sans-serif",
         "color":"#000000"
      },
      "unitPrice":{
         "font-family":"Lato, sans-serif",
         "color":"#000000"
      },
      "description":{
         "font-family":"Lato, sans-serif",
         "color":"#000000"
      }
   },
   "googleFonts":[
      "Lato"
   ],
   "text":{
      "button":"Add to basket"
   },
   "events": {
      "addVariantToCart": addToBasket,
      "openModal": viewProduct
 }
},
"option":{
   "styles":{
      "label":{
         "font-family":"Lato, sans-serif",
         "font-weight":"bold",
         "color":"#000000"
      },
      "select":{
         "font-family":"Lato, sans-serif",
         "font-weight":"bold"
      }
   },
   "googleFonts":[
      "Lato"
   ]
},
"cart":{
   "styles":{
      "button":{
         "font-family":"Lato, sans-serif",
         "font-weight":"bold",
         ":hover":{
            "background-color":"#46b893"
         },
         "background-color":"#4ecca3",
         ":focus":{
            "background-color":"#46b893"
         }
      },
      "title":{
         "color":"#000000"
      },
      "header":{
         "color":"#000000"
      },
      "lineItems":{
         "color":"#000000"
      },
      "subtotalText":{
         "color":"#000000"
      },
      "subtotal":{
         "color":"#000000"
      },
      "notice":{
         "color":"#000000"
      },
      "currency":{
         "color":"#000000"
      },
      "close":{
         "color":"#000000",
         ":hover":{
            "color":"#000000"
         }
      },
      "empty":{
         "color":"#000000"
      },
      "noteDescription":{
         "color":"#000000"
      },
      "discountText":{
         "color":"#000000"
      },
      "discountIcon":{
         "fill":"#000000"
      },
      "discountAmount":{
         "color":"#000000"
      }
   },
   "text":{
      "title":"Basket",
      "total":"Subtotal",
      "empty":"Your basket is empty.",
      "button":"Checkout"
   },
   "popup":false,
   "googleFonts":[
      "Lato"
   ]
},
"toggle":{
   "styles":{
      "toggle":{
         "font-family":"Lato, sans-serif",
         "font-weight":"bold",
         "background-color":"#4ecca3",
         ":hover":{
            "background-color":"#46b893"
         },
         ":focus":{
            "background-color":"#46b893"
         }
      }
   },
   "googleFonts":[
      "Lato"
   ]
},
"lineItem":{
   "styles":{
      "variantTitle":{
         "color":"#000000"
      },
      "title":{
         "color":"#000000"
      },
      "price":{
         "color":"#000000"
      },
      "fullPrice":{
         "color":"#000000"
      },
      "discount":{
         "color":"#000000"
      },
      "discountIcon":{
         "fill":"#000000"
      },
      "quantity":{
         "color":"#000000"
      },
      "quantityIncrement":{
         "color":"#000000",
         "border-color":"#000000"
      },
      "quantityDecrement":{
         "color":"#000000",
         "border-color":"#000000"
      },
      "quantityInput":{
         "color":"#000000",
         "border-color":"#000000"
      }
   }
 }
}

/* ----------------------------------------- */

export const detailsOptions = {
"product":{
    "contents": {
      "img": false,
      "imgWithCarousel": true,
      'description': true,
      "sizes": true,
      "button": false,
      "buttonWithQuantity": true,
      "paymentMethods": true
    },
  "order": [
    'imgWithCarousel',
    'title',
    'price',
    'options',
    'description',
    'sizes',
    'buttonWithQuantity',
    'paymentMethods'
  ],
  "layout": "horizontal",
  "imageSize":"1024x1024",
  "width": '1024px',
  "templates": {
     "img": '{{#data.currentImage.src}}<div class="{{data.classes.product.imgWrapper}}" data-element="product.imgWrapper"><img data-element="product.img" class="{{data.classes.product.img}}" src="{{data.currentImage.src}}" /></div>{{/data.currentImage.src}}',
     "sizes": '<div class="{{data.classes.product.sizes}}"><a class="{{data.classes.product.sizes-link}}" target="_blank" href="/images/creator-size-guide.png">Size guide</a></div>',
     "paymentMethods": '<div><img class="{{data.classes.product.payment-method}}" src="/images/shop-pay-logo.png"/><img class="{{data.classes.product.payment-method}}" src="/images/google-pay-logo.svg"/><img class="{{data.classes.product.payment-method}}" src="/images/apple-pay-logo.svg"/></div>'
   },
  "classes": {
    "sizes": "product-sizes",
    "sizes-link": "product-sizes-link",
    "payment-method": "paymentMethodLogo"
  },
   "styles":{
     "title": {
       "font-size": "22px"
     },
     "price": {
       "font-size": "18px",
       "font-weight": "bold",
       "margin-bottom": "3px"
     },
     "sizes": {
       "@media (min-width: 500px)":{
         "margin-left": "calc(40% + 25px)",
       },
       "@media (min-width: 680px)":{
         "margin-left": "calc(60% + 25px)",
       },
       "margin-top": "30px",
     },
     "sizes-link": {
       "color": "#4a4a4a",
       ":visited": {
         "color": "#4a4a4a"
       },
       ":hover": {
         "color": "rgba(78,204,163,1)"
       }
     },
     "payment-method": {
        "display": "inline-block",
        "width": "80px !important",
        "padding-left": "25px",
        "padding-top": "0.5rem",
        "vertical-align": "middle"
     },
      "button":{
         "font-family":"Lato, sans-serif",
         "font-weight":"bold",
         ":hover":{
            "background-color":"#46b893"
         },
         "background-color":"#4ecca3",
         ":focus":{
            "background-color":"#46b893"
         },
         "padding-left":"50px",
         "padding-right":"50px"
      },
   },
   "text":{
      "button":"Add to basket"
   },
   "googleFonts":[
      "Lato"
   ],
   "events": {
      "addVariantToCart": addToBasket,
   }
 },
 "option":{
    "styles":{
       "label":{
          "font-family":"Lato, sans-serif",
          "font-weight":"bold",
          "color":"#000000"
       },
       "select":{
          "font-family":"Lato, sans-serif",
          "font-weight":"bold"
       }
    },
    "googleFonts":[
       "Lato"
    ]
 },
 "cart":{
    "styles":{
       "button":{
          "font-family":"Lato, sans-serif",
          "font-weight":"bold",
          ":hover":{
             "background-color":"#46b893"
          },
          "background-color":"#4ecca3",
          ":focus":{
             "background-color":"#46b893"
          }
       },
       "title":{
          "color":"#000000"
       },
       "header":{
          "color":"#000000"
       },
       "lineItems":{
          "color":"#000000"
       },
       "subtotalText":{
          "color":"#000000"
       },
       "subtotal":{
          "color":"#000000"
       },
       "notice":{
          "color":"#000000"
       },
       "currency":{
          "color":"#000000"
       },
       "close":{
          "color":"#000000",
          ":hover":{
             "color":"#000000"
          }
       },
       "empty":{
          "color":"#000000"
       },
       "noteDescription":{
          "color":"#000000"
       },
       "discountText":{
          "color":"#000000"
       },
       "discountIcon":{
          "fill":"#000000"
       },
       "discountAmount":{
          "color":"#000000"
       }
    },
    "text":{
       "title":"Basket",
       "total":"Subtotal",
       "empty":"Your basket is empty.",
       "button":"Checkout"
    },
    "popup":false,
    "googleFonts":[
       "Lato"
    ]
 },
 "toggle":{
    "styles":{
       "toggle":{
          "font-family":"Lato, sans-serif",
          "font-weight":"bold",
          "background-color":"#4ecca3",
          ":hover":{
             "background-color":"#46b893"
          },
          ":focus":{
             "background-color":"#46b893"
          }
       }
    },
    "googleFonts":[
       "Lato"
    ]
 },
 "lineItem":{
    "styles":{
       "variantTitle":{
          "color":"#000000"
       },
       "title":{
          "color":"#000000"
       },
       "price":{
          "color":"#000000"
       },
       "fullPrice":{
          "color":"#000000"
       },
       "discount":{
          "color":"#000000"
       },
       "discountIcon":{
          "fill":"#000000"
       },
       "quantity":{
          "color":"#000000"
       },
       "quantityIncrement":{
          "color":"#000000",
          "border-color":"#000000"
       },
       "quantityDecrement":{
          "color":"#000000",
          "border-color":"#000000"
       },
       "quantityInput":{
          "color":"#000000",
          "border-color":"#000000"
       }
    }
  }
}


/*

<div id='product-component-1594041910681'></div>
(function () {
  var scriptURL = 'https://sdks.shopifycdn.com/buy-button/latest/buy-button-storefront.min.js';
  if (window.ShopifyBuy) {
    if (window.ShopifyBuy.UI) {
      ShopifyBuyInit();
    } else {
      loadScript();
    }
  } else {
    loadScript();
  }
  function loadScript() {
    var script = document.createElement('script');
    script.async = true;
    script.src = scriptURL;
    (document.getElementsByTagName('head')[0] || document.getElementsByTagName('body')[0]).appendChild(script);
    script.onload = ShopifyBuyInit;
  }
  function ShopifyBuyInit() {
    var client = ShopifyBuy.buildClient({
      domain: 'mountyama.myshopify.com',
      storefrontAccessToken: '194d53fd0e5bbba6c17c45d3046e44dd',
    });
    ShopifyBuy.UI.onReady(client).then(function (ui) {
      ui.createComponent('product', {
        id: '4559098085431',
        node: document.getElementById('product-component-1594041910681'),
        moneyFormat: '%C2%A3%7B%7Bamount%7D%7D',
        options: {
  "product": {
    "styles": {
      "product": {
        "@media (min-width: 601px)": {
          "max-width": "calc(25% - 20px)",
          "margin-left": "20px",
          "margin-bottom": "50px"
        },
        "carousel-button": {
          "display": "none"
        }
      },
      "title": {
        "font-family": "Lato, sans-serif",
        "color": "#000000"
      },
      "button": {
        "font-family": "Lato, sans-serif",
        "font-weight": "bold",
        ":hover": {
          "background-color": "#46b893"
        },
        "background-color": "#4ecca3",
        ":focus": {
          "background-color": "#46b893"
        }
      },
      "price": {
        "font-family": "Lato, sans-serif",
        "color": "#000000"
      },
      "compareAt": {
        "font-family": "Lato, sans-serif",
        "color": "#000000"
      },
      "unitPrice": {
        "font-family": "Lato, sans-serif",
        "color": "#000000"
      }
    },
    "contents": {
      "img": false,
      "imgWithCarousel": true
    },
    "width": "580px",
    "text": {
      "button": "Add to cart"
    },
    "googleFonts": [
      "Lato"
    ]
  },
  "productSet": {
    "styles": {
      "products": {
        "@media (min-width: 601px)": {
          "margin-left": "-20px"
        }
      }
    }
  },
  "modalProduct": {
    "contents": {
      "img": false,
      "imgWithCarousel": true,
      "button": false,
      "buttonWithQuantity": true
    },
    "styles": {
      "product": {
        "@media (min-width: 601px)": {
          "max-width": "100%",
          "margin-left": "0px",
          "margin-bottom": "0px"
        }
      },
      "button": {
        "font-family": "Lato, sans-serif",
        "font-weight": "bold",
        ":hover": {
          "background-color": "#46b893"
        },
        "background-color": "#4ecca3",
        ":focus": {
          "background-color": "#46b893"
        }
      },
      "title": {
        "font-family": "Lato, sans-serif",
        "color": "#000000"
      },
      "price": {
        "font-family": "Lato, sans-serif",
        "color": "#000000"
      },
      "compareAt": {
        "font-family": "Lato, sans-serif",
        "color": "#000000"
      },
      "unitPrice": {
        "font-family": "Lato, sans-serif",
        "color": "#000000"
      },
      "description": {
        "font-family": "Lato, sans-serif",
        "color": "#000000"
      }
    },
    "googleFonts": [
      "Lato"
    ],
    "text": {
      "button": "Add to basket"
    }
  },
  "cart": {
    "styles": {
      "button": {
        "font-family": "Lato, sans-serif",
        "font-weight": "bold",
        ":hover": {
          "background-color": "#46b893"
        },
        "background-color": "#4ecca3",
        ":focus": {
          "background-color": "#46b893"
        }
      },
      "title": {
        "color": "#000000"
      },
      "header": {
        "color": "#000000"
      },
      "lineItems": {
        "color": "#000000"
      },
      "subtotalText": {
        "color": "#000000"
      },
      "subtotal": {
        "color": "#000000"
      },
      "notice": {
        "color": "#000000"
      },
      "currency": {
        "color": "#000000"
      },
      "close": {
        "color": "#000000",
        ":hover": {
          "color": "#000000"
        }
      },
      "empty": {
        "color": "#000000"
      },
      "noteDescription": {
        "color": "#000000"
      },
      "discountText": {
        "color": "#000000"
      },
      "discountIcon": {
        "fill": "#000000"
      },
      "discountAmount": {
        "color": "#000000"
      }
    },
    "text": {
      "title": "Basket",
      "total": "Subtotal",
      "empty": "Your basket is empty.",
      "button": "Checkout"
    },
    "popup": false,
    "googleFonts": [
      "Lato"
    ]
  },
  "toggle": {
    "styles": {
      "toggle": {
        "font-family": "Lato, sans-serif",
        "font-weight": "bold",
        "background-color": "#4ecca3",
        ":hover": {
          "background-color": "#46b893"
        },
        ":focus": {
          "background-color": "#46b893"
        }
      }
    },
    "googleFonts": [
      "Lato"
    ]
  },
  "lineItem": {
    "styles": {
      "variantTitle": {
        "color": "#000000"
      },
      "title": {
        "color": "#000000"
      },
      "price": {
        "color": "#000000"
      },
      "fullPrice": {
        "color": "#000000"
      },
      "discount": {
        "color": "#000000"
      },
      "discountIcon": {
        "fill": "#000000"
      },
      "quantity": {
        "color": "#000000"
      },
      "quantityIncrement": {
        "color": "#000000",
        "border-color": "#000000"
      },
      "quantityDecrement": {
        "color": "#000000",
        "border-color": "#000000"
      },
      "quantityInput": {
        "color": "#000000",
        "border-color": "#000000"
      }
    }
  }
},
      });
    });
  }
})();
/*]]>


 */
