import React, { useState } from 'react';
import styles from './style/menu.module.css';

const Menu = (props) => {

  const {activeLink} = {...props};

  const [navOpen, setNavOpen] = useState(false);

  return(
    <nav className={`navbar navbar-expand-lg fixed-top ${styles.menuBar}`}>
      <a className={`navbar-brand ${styles.brandName}`} href="/">mount yama</a>
      <button className="navbar-toggler" type="button" onClick={setNavOpen.bind(null, !navOpen)} data-toggle="collapse" data-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
        <span className={`navbar-toggler-icon ${styles.toggleIcon}`}></span>
      </button>
      <div className={`${navOpen ? '' : 'collapse'} navbar-collapse`} id="navbarNavDropdown">
        <ul className="navbar-nav">
          <li className={`nav-item ml-sm-4 ${styles.navItem} ${activeLink === 'browse' ? 'active' : ''}`}>
            <a className="nav-link" href="/browse">Shop <span className="sr-only">(shop)</span></a>
          </li>
          <li className={`nav-item ml-sm-4 ${styles.navItem} ${activeLink === 'about' ? 'active' : ''}`}>
            <a className="nav-link" href="/about">About Us <span className="sr-only">(about us)</span></a>
          </li>
        </ul>
      </div>
    </nav>

  )
}

export default Menu;
