import firebase from 'firebase';

const firebaseConfig = {
  apiKey: "AIzaSyCv4-BdnsmLbhLBo5oWcXOi_cAozN1l4qQ",
  authDomain: "mount-yama.firebaseapp.com",
  databaseURL: "https://mount-yama.firebaseio.com",
  projectId: "mount-yama",
  storageBucket: "mount-yama.appspot.com",
  messagingSenderId: "597200361738",
  appId: "1:597200361738:web:b03da64615900fea8842f3",
  measurementId: "G-RQZVL4PV69"
};

firebase.initializeApp(firebaseConfig);
export const auth = firebase.auth;
export const db = firebase.database();
export const ga = firebase.analytics();
