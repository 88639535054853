import React, { Component, lazy, Suspense } from 'react';
import {
  Route,
  BrowserRouter as Router,
  Switch,
  Redirect,
} from "react-router-dom";
import MainLayout from './components/MainLayout';
import Home from './pages/Home';
// import Signup from './pages/Signup';
// import Login from './pages/Login';
import { auth } from './services/firebase';

import logo from './logo.svg';


function PrivateRoute({ component: Component, authenticated, ...rest }) {
  return (
    <Route
      {...rest}
      render={(props) => authenticated === true
        ? <Component {...props} />
        : <Redirect to={{ pathname: '/login', state: { from: props.location } }} />}
    />
  )
}

function PublicRoute({ component: Component, authenticated, ...rest }) {
  return (
    <Route
      {...rest}
      render={(props) => authenticated === false
        ? <Component {...props} />
      : <Redirect to='/' />}
    />
  )
}


function App() {

  let authenticated = false;

  auth().onAuthStateChanged((user) => {
    if(user) {
      authenticated = true;
    }
  })

  function renderLoader() {
    return <></>
  }

  const Browse = lazy(() => import('./pages/Browse'));
  const Product = lazy(() => import('./pages/Product'));
  const About = lazy(() => import('./pages/About'));
  const Privacy = lazy(() => import('./pages/Privacy'));
  const Returns = lazy(() => import('./pages/Returns'));
  const Terms = lazy(() => import('./pages/Terms'));

  return (
    <Router>
      <MainLayout>
        <Switch>
          <Suspense fallback={renderLoader()}>
            <Route exact path="/" component={Home}></Route>
            <PublicRoute path="/browse" authenticated={authenticated} component={Browse}></PublicRoute>
            <PublicRoute path="/product/:id" authenticated={authenticated} component={Product}></PublicRoute>
            <PublicRoute path="/about" authenticated={authenticated} component={About}></PublicRoute>
            <PublicRoute path="/privacy/" authenticated={authenticated} component={Privacy}></PublicRoute>
            <PublicRoute path="/returns/" authenticated={authenticated} component={Returns}></PublicRoute>
            <PublicRoute path="/terms/" authenticated={authenticated} component={Terms}></PublicRoute>
          </Suspense>
      </Switch>
      </MainLayout>
    </Router>
  );
}

export default App;
