import React, { useState } from 'react';
import { ga } from '../services/firebase.js';
import styles from './style/emailSignUp.module.css';


const EmailSignUp = (props) => {

  const [subscribed, setSubscribed] = useState(false);

  if (document.readyState !== 'loading'){
    addSubmitListener();
  } else {
    document.addEventListener('DOMContentLoaded', addSubmitListener);
  }

  function addSubmitListener() {
    // Access the form element...
    var form = document.getElementById("subscribe-form");
    if(!form){ return }
    // ...and take over its submit event.
    form.addEventListener("submit", function (event) {
      event.preventDefault();  // Prevents form from submitting
      if (this.checkValidity()) sendData();
    });

    function sendData(){
      // Applying XMLHttpRequest form submit to skip Jquery load in store's website and prevent
      // jquery version errors
      // https://developer.mozilla.org/en-US/docs/Learn/HTML/Forms/Sending_forms_through_JavaScript
      var request = new XMLHttpRequest();
      // Bind the FormData object and the form element
      var FD = new FormData(form);
      // Define what happens on successful data submission
      request.addEventListener('load', function(event) {
        var data = JSON.parse(request.responseText);
        setSubscribed(true)
      });

      // Define what happens in case of error
      request.addEventListener('error', function(event) {
        var error = event.target.responseText;
        setSubscribed(true)
      });

      try {
          ga.logEvent('email_signup');
          // Set up our request
          request.open('POST', form.action + '.json', true);
          // The data sent is what the user provided in the form
          request.send(FD);
      } catch (e) {

      }
    }
  }

  function renderForm()
  {
    if(subscribed)
    {
      return (
        <div>Subscribed!</div>
      )
    }
    else
    {
      return (
        <form action='https://go.smartrmail.com/en/subscribe/8oqeea' method='post' className={`${styles["form-horizontal"]} ${styles["subscribe-form"]}`} id="subscribe-form">
          <input name="subscribe_form[anti_bot]" type="text" style={{display: "none"}} />
          <div className='container-fluid'>
        <div className="row">
            <div className={`col-sm-4 ${styles['email-input']}`}>
              <input required id="subscribe_form_email" name="EMAIL" type="email" className={`${styles["form-control"]}`} placeholder="your@email.com"/>
            </div>
            <div className={`col-sm-4 ${styles['email-input']}`}>
              <input required id='subscribe_form_first_name' name='FNAME' type='text' className={`${styles["form-control"]}`} placeholder="name"/>
            </div>
            <div className={`col-sm-4 ${styles['email-input']}`}>
              <button className={`${styles["btn"]} ${styles["btn-lg"]} ${styles["btn-primary"]} ${styles["subscribe-form-submit"]}`}>Subscribe</button>
            </div>
          </div>
        </div>
        </form>
      )
    }
  }

  return(
    <div className={`${styles["smartrmail-embedded"]}`}>
      <div className={`${styles["container"]} ${styles["subscribe-form-body"]}`}>
        <div className={`${styles["row horizontal"]}`}>
          <div className={`${styles["col-12"]}`}>
              <h6 className={`${styles["subscribe-form-heading"]}`}>Subscribe to our newsletter</h6>
              <p>Be the first the know when new designs are released</p>
              {renderForm()}
          </div>
        </div>
      </div>
    </div>
  )
}

export default EmailSignUp;
