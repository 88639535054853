import React from 'react';
import ReactDOM from 'react-dom';
import { Helmet } from 'react-helmet';

// import './index.css';
import App from './App';
//import * as serviceWorker from './serviceWorker';
import { scriptUrl } from "./services/shopify";

import 'bootstrap/dist/css/bootstrap.css';
import branding from "./style/branding.css";


ReactDOM.render(
  <React.StrictMode>
    <Helmet>
      <script src="https://cmp.osano.com/Azyw5tRzAepR3dRA/c9177807-17e8-4d53-9131-6f2dd4f43ada/osano.js"></script>
      <script type="text/javascript" src={scriptUrl}/>
      <script type="text/javascript" src="//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js" async></script>

      <link href="https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,400;0,700;1,400;1,700&display=swap" rel="stylesheet"/>
    </Helmet>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
//serviceWorker.unregister();
